import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Paragraph from "../../../../../components/paragraph"
import Bullet from "../../../../../components/bullet"
import VideoPlayer from "../../../../../components/video-player"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const data = useStaticQuery(graphql`
    query HoelderlinSprechenQuery {
      poster: file(relativePath: { eq: "kurse/poster-reiner-andenken.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/02-dichterwerkstatt/gehen-sprechen" />
      }
      navigation={navigation}
    >
      <Seo title="Das Gedicht sprechen" />
      <Stack>
        <Heading as="h2" level={2}>
          Das Gedicht sprechen
        </Heading>
        <Paragraph>
          Wie klingt das nun gesprochen? Hölderlins Gedichte sind für viele
          Sprecher*innen und Schauspieler*innen eine große Herausforderung. Umso
          unterschiedlicher und kreativer haben sie sich diesen Texten
          angenähert und sie in eine (Bühnen-)Performance übersetzt. Der Wiener
          Sprechkünstler Christian Reiner geht dabei einen ganz eigenen Weg.
          Seht euch das Video an. Wie wirkt das Gedicht hier auf euch? Wie
          würdet ihr die einzelnen Aspekte des Vortrags bewerten?
        </Paragraph>
        <VideoPlayer src="kurse/reiner-andenken" poster={data.poster} />
        <Stack space={3}>
          <Paragraph>
            Seht euch das Video an und achtet auf folgende Dinge:
          </Paragraph>
          <Bullet>Stimme</Bullet>
          <Bullet>Tempo</Bullet>
          <Bullet>Rhythmus</Bullet>
          <Bullet>Körperhaltung</Bullet>
          <Bullet>Gestik</Bullet>
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
